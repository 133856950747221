import { Button, IconButton, InputBar } from '~/ui';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import { Modal } from '~/components/Shared/Modal/Modal';
import { useTranslation } from 'react-i18next';
import PhotoIcon from './images/image-icon.svg?react';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useToastError } from '~/utils/useToastError';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { IconsContainer } from '../CreateCategory/IconsContainer/IconsContainer';
import { validate } from '~/utils/validateKnowledge';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import { ICreateProps, IFormik } from '../CreateCategory/CreateCategory';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { SvgIcon } from '~/ui/SvgIcon/SvgIcon';
import { svgIcons } from '~/utils/getIcons';
import ReactDOMServer from 'react-dom/server';
import DeleteSvg from '~/assets/svg/newSvg/delete.svg?react';
import styles from './EditCategory.module.scss';

const EditCategory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { categoryId } = useParams();
  const { user } = useContext(AuthenticationContext);
  const isSuperAdmin = checkIfUserSuperAdmin(user?.data.role_name);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const client = useQueryClient();

  const { mutateAsync: editCategory } = useMutation(
    async ({ name, assets, icon }: ICreateProps) => {
      const response = await instance.put(
        `admin/knowledge-base-categories/${categoryId}`,
        {
          name,
          asset_id: assets.id,
          svg_icon: icon,
        }
      );

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['knowledge-data', categoryId]);
        navigate('/knowledge-base');
        toastSuccess(t('new_accommodation_added'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { data: knowledgeData, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get(
        `admin/knowledge-base-categories/${categoryId}`
      );
      return data;
    },
    queryKey: ['knowledge-data', categoryId],
    onError: error => {
      toastError(error);
    },
  });

  const { data: assetsArray, isLoading: isAssetsLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/assets');
      return data.data;
    },
    queryKey: ['assets'],
    onError: error => {
      toastError(error);
    },
  });

  const formik = useFormik<IFormik>({
    initialValues: {
      name: '',
      assets: {
        id: 0,
        name: '',
      },
      iconId: null,
      icon: null,
    },
    validate,
    onSubmit: values => {
      const stringIcon =
        values.icon !== null
          ? ReactDOMServer.renderToStaticMarkup(values.icon)
          : '';
      const data = {
        name: values.name,
        assets: values.assets,
        icon: stringIcon,
      };
      editCategory(data);
    },
  });

  const setValues = formik.setValues;

  useEffect(() => {
    if (formik.values.iconId === null) return;
    const Icon = svgIcons[formik.values.iconId].icon;
    formik.setFieldValue('icon', <Icon className={styles.icon} />);
  }, [formik.values.iconId]);

  useEffect(() => {
    if (!knowledgeData && isLoading) return;

    const icon = (
      <SvgIcon
        svgString={knowledgeData.svg_icon}
        className={styles.comingIcon}
      />
    );

    const currentAsset = assetsArray?.filter(
      (item: { id: number }) => item.id === knowledgeData.asset_id
    );

    setValues({
      name: knowledgeData.name,
      assets: {
        id: isSuperAdmin ? currentAsset[0].id : assetsArray[0].id,
        name: isSuperAdmin ? currentAsset[0].name : assetsArray[0].name,
      },
      iconId: null,
      icon: icon,
    });
  }, [categoryId, setValues, knowledgeData, assetsArray]);

  return (
    <Page
      heading={
        <Heading
          text={t('edit_category')}
          classNameText={styles.createCategoryHeading}
        />
      }
    >
      <form className={styles.createCategory} onSubmit={formik.handleSubmit}>
        <InputBar
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          classNameInput={styles.input}
          label={t('category_name')}
          placeholder={t('category_name')}
          star
          errors={formik.errors.name}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />

        {!isAssetsLoading && (
          <CustomSelect
            placeholder={t('to_choose')}
            onChange={value => {
              formik.setFieldValue('assets', value);
            }}
            label={t('asset')}
            value={formik.values.assets}
            disabled={!isSuperAdmin || isAssetsLoading}
            options={assetsArray}
            error={formik.errors.assets ? formik.errors.assets.name : undefined}
            onTouch={() => formik.setFieldTouched('assets', true)}
            required
          />
        )}

        {formik.values.icon !== null ? (
          <div className={styles.iconWrapper}>
            {formik.values.icon}
            <button
              type="button"
              className={styles.deleteBtd}
              onClick={() => {
                formik.setFieldValue('iconId', null);
                formik.setFieldValue('icon', null);
              }}
            >
              <DeleteSvg />
            </button>
          </div>
        ) : (
          <div className={styles.iconBtnWrapper}>
            <IconButton
              onClick={() => setIsModalOpen(true)}
              className={styles.photoPickerButton}
            >
              <PhotoIcon />
              <span className={styles.photoPickerText}>
                {t('choose_category_icon')}
              </span>
            </IconButton>
            {formik.errors.icon && (
              <span className={styles.iconBtnError}>{t('select_icon')}</span>
            )}
          </div>
        )}
        <Button
          type="submit"
          text={t('save_n_exit')}
          className={styles.submitButton}
          disabled={
            !formik.values.name ||
            !formik.values.icon ||
            !formik.values.assets.name
          }
        />
      </form>

      <Modal
        modalStyles={styles.photosModal}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <IconsContainer
          onCloseClick={() => setIsModalOpen(false)}
          setSelectedId={value => formik.setFieldValue('iconId', value)}
        />
      </Modal>
    </Page>
  );
};

export default EditCategory;
