import {
  Accommodation,
  Food,
  Dish,
  User,
  Feedback,
  Notification,
  Announcement,
  Request,
  Transaction,
  Asset,
  Knowledge,
  Article,
} from './DynamicBreadcrumbs';
import i18next from 'i18next';

const loadRoutes = () => {
  const t = i18next.t;

  return [
    // accommodations
    { path: '/accommodations', breadcrumb: t('accommodations') },
    {
      path: '/accommodations/create',
      breadcrumb: t('creating_placement'),
    },
    { path: '/accommodations/:accommodationID', breadcrumb: Accommodation },

    // food
    { path: '/food', breadcrumb: t('food_outlets') },
    { path: '/food/create', breadcrumb: t('creating_food_point') },
    { path: '/food/:foodID', breadcrumb: Food },
    {
      path: '/food/:foodID/persons/create',
      breadcrumb: t('manager_person_creation'),
    },
    { path: '/food/:foodID/info', breadcrumb: t('general_information') },
    { path: '/food/:foodID/types', breadcrumb: t('food_category') },
    { path: '/food/:foodID/persons', breadcrumb: t('responsible_persons') },
    {
      path: '/food/:foodID/persons/:personID',
      breadcrumb: t('editing_person'),
    },
    {
      path: '/food/:foodID/current',
      breadcrumb: t('current_menu'),
    },
    {
      path: '/food/:foodID/orders',
      breadcrumb: t('orders'),
    },
    {
      path: '/food/:foodID/orders/:transactionID',
      breadcrumb: Transaction,
    },
    { path: '/food/:foodID/menu/:dishID', breadcrumb: Dish },

    // social
    { path: '/social', breadcrumb: t('social_survey') },
    { path: '/social/create', breadcrumb: t('create_social_survey') },

    // user
    { path: '/user', breadcrumb: t('all_employees') },
    { path: '/user/create', breadcrumb: t('adding_new_employee') },
    { path: '/user/:userID', breadcrumb: User },

    // feedback
    { path: '/feedback', breadcrumb: t('feedback') },
    { path: '/feedback/:feedbackID', breadcrumb: Feedback },
    {
      path: '/feedback/:feedbackID/comment',
      breadcrumb: t('feedback_response'),
    },

    // announcements
    { path: '/announcements', breadcrumb: t('announcements') },
    { path: '/announcements/create', breadcrumb: t('create_announcement') },
    { path: '/announcements/:announcementID', breadcrumb: Announcement },

    // notifications
    { path: '/notifications', breadcrumb: t('notifications') },
    {
      path: '/notifications/create',
      breadcrumb: t('create_notification'),
    },
    { path: '/notifications/:notificationID', breadcrumb: Notification },

    // requests
    { path: '/requests', breadcrumb: t('request_list') },
    { path: '/requests/:requestID', breadcrumb: Request },

    // qr
    { path: '/qr', breadcrumb: t('qr') },
    { path: '/qr/create', breadcrumb: t('qr_code_create') },

    // transactions
    { path: '/transactions', breadcrumb: t('orders') },
    { path: '/transactions/:transactionID', breadcrumb: Transaction },

    // questionnaire
    { path: '/questionnaires', breadcrumb: t('questionnaires') },
    { path: '/questionnaires/create', breadcrumb: t('create_questionnaire') },
    {
      path: '/questionnaires/:questionnaireID',
      breadcrumb: t('edit_questionnaire'),
    },
    {
      path: '/questionnaires/:questionnaireID/results',
      breadcrumb: t('questionnaire_results'),
    },

    // assets
    { path: '/assets', breadcrumb: t('assets') },
    { path: '/assets/create', breadcrumb: t('new_asset') },
    { path: '/assets/:assetID/edit', breadcrumb: Asset },

    // settings
    { path: '/settings', breadcrumb: t('settings') },

    // knowledge
    { path: '/knowledge-base', breadcrumb: t('knowledge_base') },
    {
      path: '/knowledge-base/create',
      breadcrumb: t('create_new_category'),
    },
    { path: '/knowledge-base/:id', breadcrumb: Knowledge },
    {
      path: `/knowledge-base/:categoryId/edit`,
      breadcrumb: t('edit_category'),
    },
    {
      path: '/knowledge-base/:categoryId/create-article',
      breadcrumb: t('creating_article'),
    },
    {
      path: '/knowledge-base/:categoryId/:editArticleId',
      breadcrumb: Article,
    },
  ];
};

export default loadRoutes;
