import styles from './MobileHeading.module.scss';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '~/assets/svg/newSvg/arrow-select.svg?react';

interface IHeadingProps {
  title: string;
}

const MobileHeading = ({ title }: IHeadingProps) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <div className={styles.titleWrapper}>
      <h1 className={styles.title}>{title}</h1>
      <button onClick={handleNavigate} className={styles.arrowBtn}>
        <ArrowIcon className={styles.arrow} />
      </button>
    </div>
  );
};

export default MobileHeading;
